<template>
  <a-card :bordered='false'>
    <div class="main_bd">
      <div class="mod_default_box">
        <div class="mod_default_hd user_admin">
          <h4>管理员</h4>
          <div class="mod_hd_desc">
            <p class="desc">可设置风险操作保护、风险操作提醒等帐号安全</p>
          </div>
          <div class="mod_hd_extra"></div>
        </div>
        <div class="mod_default_bd default_box user_admin">
          <div class="user_list">
            <div class="user_item">
              <div class="user_item_inner">
                <div class="user_opr"><a href="/wxopen/admin?action=rebindadminpage&amp;token=18924407&amp;lang=zh_CN">修改</a></div>
                <div class="user_info"><img src="https://wx.qlogo.cn/mmhead/qbvaL9taELtyEjzlPF8LdPhb5Cg7UqGeH9GYV5Q21X6o5JnQTItC9w/0" class="user_avatar"><strong title="刘密@营销数字化新基建" class="user_nickname">刘密@营销数字化新基建</strong><strong title="h****315" class="user_name">h****315</strong>
                  <p class="user_time">2020-03-31</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mod_default_box permission">
        <div class="mod_default_hd">
          <h4>项目成员</h4>
          <div class="mod_hd_desc">
            <p class="desc">管理员可添加小程序项目成员，并配置成员的权限，查看</p><a href="http://kf.qq.com/faq/170302zeQryI170302beuEVn.html" target="_blank" class="desc_opr">详细说明</a>
          </div>
          <div class="mod_hd_extra" style="float:right">
            <p class="desc">
             <a-button type="primary">
                添加
              </a-button>
            </p>
          </div>
        </div>
        <div class="mod_default_bd">
          <div class="table_wrp table-fixed-scroll">
            <div class="table-scroll">
              <div class="table_container">
                <table class="table permission">
                  <colgroup>
                    <col name="role_cell" width="22">
                    <col name="role_cell" width="160">
                  </colgroup>
                  <thead class="thead">
                    <tr>
                      <th class="table_cell opr_cell"></th>
                      <th class="table_cell role_cell">
                        <div class="dropdown_wrp">
                          <div class="dropdown_menu in_th">
                            <div class="weui-desktop-form__dropdown weui-desktop-form__dropdown__inner-table">
                              <dl class="weui-desktop-form__dropdown-label">
                                <dt class="weui-desktop-form__dropdown__dt weui-desktop-form__dropdown__inner-table">
                                  <!---->
                                  全部成员
                                </dt>
                                <div class="weui-desktop-dropdown-menu weui-desktop-dropdown-menu_bottom" style="display: none;">
                                  <ul class="weui-desktop-dropdown__list">
                                    <!---->
                                    <li class="weui-desktop-dropdown__list-ele checked">
                                      <div class="weui-desktop-dropdown__list-ele-contain">
                                        <span class="weui-desktop-dropdown__list-ele__text">全部成员</span>
                                      </div>
                                    </li>
                                    <li class="weui-desktop-dropdown__list-ele">
                                      <div class="weui-desktop-dropdown__list-ele-contain">
                                        <span class="weui-desktop-dropdown__list-ele__text">运营者</span>
                                      </div>
                                    </li>
                                    <li class="weui-desktop-dropdown__list-ele">
                                      <div class="weui-desktop-dropdown__list-ele-contain">
                                        <!----> <span class="weui-desktop-dropdown__list-ele__text">开发者</span>
                                        <!---->
                                      </div>
                                      <!---->
                                    </li>
                                    <li class="weui-desktop-dropdown__list-ele">
                                      <div class="weui-desktop-dropdown__list-ele-contain">
                                        <!----> <span class="weui-desktop-dropdown__list-ele__text">数据分析者（基础分析）</span>
                                        <!---->
                                      </div>
                                      <!---->
                                    </li>
                                    <li class="weui-desktop-dropdown__list-ele">
                                      <div class="weui-desktop-dropdown__list-ele-contain">
                                        <!----> <span class="weui-desktop-dropdown__list-ele__text">数据分析者（交易分析）</span>
                                        <!---->
                                      </div>
                                      <!---->
                                    </li>
                                  </ul>
                                  <!---->
                                </div>
                                <div class="weui-desktop-dropdown-menu weui-desktop-dropdown-menu_bottom" style="display: none;">
                                  <ul class="weui-desktop-dropdown__list">
                                    <!---->
                                  </ul>
                                  <!---->
                                </div>
                                <!---->
                              </dl>
                              <!---->
                              <!---->
                            </div>
                          </div>
                        </div>
                      </th>
                      <th class="table_cell" style="width: 24%;">
                        运营者
                        <div class="weui-desktop-popover__wrp"><span class="weui-desktop-popover__target"><i class="icon_msg_mini ask"></i></span>
                          <div class="weui-desktop-popover weui-desktop-popover_pos-up-center" style="display: none;">
                            <div class="weui-desktop-popover__inner">
                              <!---->
                              <div class="weui-desktop-popover__desc">管理、推广、设置等模块权限，可使用体验版小程序</div>
                              <!---->
                            </div>
                          </div>
                        </div>
                      </th>
                      <th class="table_cell" style="width: 24%;">
                        开发者
                        <div class="weui-desktop-popover__wrp"><span class="weui-desktop-popover__target"><i class="icon_msg_mini ask"></i></span>
                          <div class="weui-desktop-popover weui-desktop-popover_pos-up-center" style="display: none;">
                            <div class="weui-desktop-popover__inner">
                              <!---->
                              <div class="weui-desktop-popover__desc">开发模块权限，可使用体验版小程序、使用开发者工具（IDE）</div>
                              <!---->
                            </div>
                          </div>
                        </div>
                      </th>
                      <th class="table_cell" style="width: 24%;">
                        数据分析者（基础分析）
                        <div class="weui-desktop-popover__wrp"><span class="weui-desktop-popover__target"><i class="icon_msg_mini ask"></i></span>
                          <div class="weui-desktop-popover weui-desktop-popover_pos-up-center" style="display: none;">
                            <div class="weui-desktop-popover__inner">
                              <!---->
                              <div class="weui-desktop-popover__desc">统计模块基础分析查看权限，可使用体验版小程序</div>
                              <!---->
                            </div>
                          </div>
                        </div>
                      </th>
                      <th class="table_cell" style="width: 24%;">
                        数据分析者（交易分析）
                        <div class="weui-desktop-popover__wrp"><span class="weui-desktop-popover__target"><i class="icon_msg_mini ask"></i></span>
                          <div class="weui-desktop-popover weui-desktop-popover_pos-up-center" style="display: none;">
                            <div class="weui-desktop-popover__inner">
                              <!---->
                              <div class="weui-desktop-popover__desc">统计模块交易分析查看权限，可使用体验版小程序</div>
                              <!---->
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="tbody">
                    <tr data-idx="{idx}" class="role_row js_role_row">
                      <td class="table_cell opr_cell"><a href="javascript:;" aria-label="删除该用户" class="add_remove_opr icon_remove mpui-tooltip mpui-tooltip__top-left" style="visibility: hidden;"></a></td>
                      <td class="table_cell role_cell">
                        <div class="role_info_box"><img src="https://wx.qlogo.cn/mmhead/PiajxSqBRaEKkAPiaQyJ4EUecibyY0zTv8vRSru3QWysk04jVc0kroQKg/0" alt="" class="role_avatar"><span class="role_name">Itskamui</span></div>
                      </td>
                      <td class="table_cell"><span class="icon_common_permission icon_allow"></span></td>
                      <td class="table_cell"><span class="icon_common_permission icon_allow"></span></td>
                      <td class="table_cell"><span class="icon_common_permission icon_allow"></span></td>
                      <td class="table_cell"><span class="icon_common_permission icon_allow"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!---->
          <!---->
        </div>
        <!---->
      </div>
      <div class="mod_default_box">
        <div class="mod_default_hd user_admin">
          <h4>体验成员</h4>
          <div class="mod_hd_desc">
            <p class="desc">体验成员可使用体验版小程序，查看</p><a href="http://kf.qq.com/faq/170302zeQryI170302beuEVn.html" target="_blank" class="desc_opr">详细说明</a>
          </div>
            <div class="mod_hd_extra" style="float:right">
            <p class="desc">
             <a-button type="primary">
                添加
              </a-button>
            </p>
          </div>
        </div>
        <div class="mod_default_bd default_box">
          <div class="user_list">
            <div class="user_item">
              <div class="user_item_inner">
                <div class="user_opr"><a href="javascript:;">删除</a></div>
                <div class="user_info"><img src="https://wx.qlogo.cn/mmhead/PiajxSqBRaELwiavWsRxSNKOicMeqgcu0fY769urbuafM0mEVXbmGM2ZQ/0" class="user_avatar"><strong title="横渠" class="user_nickname">横渠</strong><strong title="L13****949" class="user_name">L13****949</strong>
                  <p class="role_remark">
                    备注：
                    <span class="role_remark_content"></span><a data-isexpr="1" data-idx="{i}" href="javascript:;" aria-label="编辑备注" class="icon14_common edit_gray mpui-tooltip"></a>
                  </p>
                </div>
              </div>
            </div>
            <div class="user_item">
              <div class="user_item_inner">
                <div class="user_opr"><a href="javascript:;">删除</a></div>
                <div class="user_info"><img src="https://wx.qlogo.cn/mmhead/dx4Y70y9Xcu1C2jJ7KEVkM8jk9e6Eyjf4oAYcGiccSLFAtH6ozUBe5g/0" class="user_avatar"><strong title="一物一码客服静文" class="user_nickname">一物一码客服静文</strong><strong title="wxid****nkfq12" class="user_name">wxid****nkfq12</strong>
                  <p class="role_remark">
                    备注：
                    <span class="role_remark_content"></span><a data-isexpr="1" data-idx="{i}" href="javascript:;" aria-label="编辑备注" class="icon14_common edit_gray mpui-tooltip"></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!---->
        </div>
      </div>
    </div>
  </a-card>
</template>
<script>
import { getSysdomain } from "@/api/appletCode";
import { onMounted } from "vue";
export default {
  setup() {
    const initData = async () => {
      let result = await getSysdomain()
        .then((res) => res.data)
        .catch((error) => error);
      console.log(result);
    };

    onMounted(() => {
      initData();
    });
  },
};
</script>
<style lang="less" scoped>
.main_bd {
  padding-top: 20px;
  .mod_default_box {
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(150, 150, 150, 0.3);
    padding: 20px 30px 30px;
  }
  .mod_default_box.permission {
    position: relative;
  }
  .mod_default_box {
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(150, 150, 150, 0.3);
    padding: 20px 30px 30px;
    .mod_default_hd {
      position: relative;
      margin-bottom: 30px;
      h4 {
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 15px;
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 0;
        padding: 0;
      }
      .mod_hd_desc {
        display: inline-block;
      }
      .desc {
        display: inline-block;
        color: #9a9a9a;
      }
      .mod_default_hd .mod_hd_extra {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 32px;
      }
    }
    .user_list {
      overflow: hidden;
      margin-right: -20px;
      margin-bottom: -20px;
      .user_item {
        float: left;
        width: 33.33%;
        .user_item_inner {
          background-color: #fafafb;
          border-radius: 5px;
          -moz-border-radius: 5px;
          -webkit-border-radius: 5px;
          margin: 0 20px 20px 0;
          padding: 14px;
        }
        .user_opr {
          float: right;
          margin-left: 1em;
          text-align: right;
          position: relative;
          z-index: 1;
        }
        .user_info {
          padding-left: 86px;
          min-height: 70px;
          position: relative;
        }
        .user_avatar {
          width: 70px;
          height: 70px;
          border-radius: 5px;
          -moz-border-radius: 5px;
          -webkit-border-radius: 5px;
          position: absolute;
          top: 0;
          left: 0;
        }
        em {
          font-weight: 400;
          font-style: normal;
          display: block;
          width: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .user_time {
          color: #9a9a9a;
        }
      }
    }
    .permission {
      padding-right: 300px;
      .mod_hd_extra {
        float: right;
      }
      .mod_default_hd {
        position: relative;
        margin-bottom: 30px;
      }
      .mod_hd_extra {
        float: left;
      }
      .mod_default_dd {
        .table-fixed-scroll {
          position: relative;
        }
      }

      .role_info_box {
        width: 220px;
        height: 60px;
        position: relative;
        padding-left: 70px;
        box-sizing: border-box;
        text-align: left;
        .role_avatar {
          width: 60px;
          height: 60px;
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
        }
        .role_name {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 20px;
          padding-top: 20px;
        }
      }
    }
  }
}
</style>